import React from "react";
import { Link, graphql } from "gatsby";
import PropTypes from "prop-types";

import Layout from "./index";
import SEO from "../components/seo";
import Grid from "../sections/blog/grid";
import Subscribe from "../sections/subscribe";

const BlogIndex = ({ data, pageContext }) => {
  const { edges: posts } = data.allMdx;
  const { currentPage, total } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === total;
  const prevPage =
    currentPage - 1 === 1 ? "/blog" : `/blog/${(currentPage - 1).toString()}`;
  const nextPage = `/blog/${(currentPage + 1).toString()}`;

  return (
    <Layout>
      <SEO keywords={["blog"]} title="Blog" />
      <div className="w-full flex flex-col items-center">
        <div className="w-full bg-mph-light-gray py-14">
          <h1 className="text-2xl sm:text-6xl text-center text-black font-bold tracking-wide">
            PM Insider: Our Blog
          </h1>
        </div>
        <div className="w-full max-w-6xl">
          <Grid posts={posts} />
          <div className="flex sm:flex-row flex-col justify-between items-center p-4">
            <div className="p-2 m-2 inline-flex flex-col">
              {!isFirst && (
                <Link
                  to={prevPage}
                  rel="prev"
                  className="text-mph-orange hover:text-mph-ocean-blue"
                >
                  ← Previous Page
                </Link>
              )}
            </div>
            <div className="p-2 m-2 sm:text-right inline-flex flex-col">
              {!isLast && (
                <Link
                  to={nextPage}
                  rel="next"
                  className="text-mph-orange hover:text-mph-ocean-blue"
                >
                  Next Page →
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <Subscribe />
    </Layout>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query blogIndex($limit: Int!, $skip: Int!) {
    allMdx(
      limit: $limit
      skip: $skip
      filter: { frontmatter: { layout: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            slug
            date
            thumbnail {
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default BlogIndex;
